
@import "settings";
@import "foundation";
@import "motion-ui";

@include foundation-flex-grid;
@include foundation-global-styles;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-flex-classes;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
// @include foundation-callout;
// @include foundation-close-button;
// @include foundation-drilldown-menu;
// @include foundation-dropdown;
// @include foundation-dropdown-menu;
// @include foundation-flex-video;
// @include foundation-label;
// @include foundation-media-object;
// @include foundation-menu;
// @include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
// @include foundation-reveal;
// @include foundation-switch;
// @include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
// @include foundation-tooltip;
// @include foundation-top-bar;

@include motion-ui-transitions;
@include motion-ui-animations;

$secondary: #9AC9ED;
$tertiary: #6fc494;

body {
  background: $tertiary;
  overflow-x: hidden;
}

.ie {
  .xriver, .yriver {
    display: none;
  }
}

.row.margin-top {
  margin-top: 2rem;
}

section#cover {
  height: 310px;
  background: lighten($secondary,5);
  position: relative;

  @media #{$medium-up} {
    height: 800px;
  }

  .middle_element {
    width: 870px;
    height: 80%;
    background: url(../img/cover.svg);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 0;
    margin: 0 auto;
    position: relative;
    z-index: 10;

    @media #{$small-only} {
      width: 100%;
      height: 80%;
    }
  }

  .xriver {
    position: absolute;
    top: 67.5%;
    right: 21%;
    width: 4%;
    height: 3%;
    z-index: 14;
    animation: xriver 20s infinite linear;
    display: block;

    @media #{$small-only} {
      display: none;
    }
  }

  .yriver {
    position: absolute;
    top: 70%;
    right: 22%;
    width: 100%;
    height: 100%;
    z-index: 14;
    animation: yriver 20s infinite linear;
    display: block;

    @media #{$small-only} {
      display: none;
    }
  }

  @keyframes xriver {
    0% {
      transform: translate(0, 0);
      opacity: 0;
    }
    3% {
      opacity: 1;
    }
    10% {
      transform: translateX(-290%);
      animation: ease-out;
    }
    15% {
      transform: translateX(-380%);
      animation: ease-in;
    }
    20% {
      transform: translateX(-290%);
    }
    30% {
      transform: translateX(-10%);
      animation: ease-out;
    }
    35% {
      transform: translateX(+90%);
      animation: ease-in;
    }
    40% {
      transform: translateX(-20%);
    }
    50% {
      transform: translateX(-225%);
      animation: ease-out;
    }
    55% {
      transform: translateX(-290%);
      animation: ease-in;
    }
    60% {
      transform: translateX(-225%);
      animation: ease-out;
    }
    80% {
      transform: translateX(+200%);
      opacity: 1;
    }
    100% {
      transform: translateX(+200%);
      opacity: 0;
    }
  }

  @keyframes yriver {
    0% {
      transform: translateY(0);
    }
    10% {
      transform: translateY(0);
      animation: ease-in;
    }
    15% {
      transform: translateY(+150%);
      animation: ease-out;
    }
    20% {
      transform: translateY(+290%);
    }
    30% {
      transform: translateY(+290%);
      animation: ease-in;
    }
    35% {
      transform: translateY(+415%);
      animation: ease-out;
    }
    40% {
      transform: translateY(+530%);
    }
    50% {
      transform: translateY(+530%);
      animation: ease-in;
    }
    55% {
      transform: translateY(+650%);
      animation: ease-out;
    }
    60% {
      transform: translateY(+775%);
    }
    100% {
      transform: translateY(+775%);
    }
  }

  .cloud1 {
    width: 35%;
    position: absolute;
    left: 2%;
    top: 3%;

    @media #{$small-only} {
      display: none;
    }
  }

  .cloud2 {
    width: 45%;
    position: absolute;
    right: 3%;
    top: 6%;
  }

  .waves {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 11;
    bottom: -5%;
    top: 70%;


    @media #{$medium-up} {
      top: 75%;
    }

    @media #{$xlarge-up} {
      top: 73%;
    }
  }

  .balloon {
    position: absolute;
    z-index: 11;
    top: 25%;
    width: 15%;
    animation: float 90s linear infinite;
  }

  .leaf {
    position: absolute;
    z-index: 13;
    width: 1.3%;

    &.leaf1 {
      right: 18%;
      bottom: 10%;
      animation: falling 18s;
      animation-delay: 10s;

      @media #{$xlarge-up} {
        bottom: 10%;
      }

      @media #{$medium-only} {
        bottom: 15%;
      }

      @media #{$small-only} {
        display: none;
      }
    }

    &.leaf2 {
      left: 14%;
      bottom: 15%;
      animation: falling 18s;
      animation-delay: 30s;

      @media #{$xlarge-up} {
        bottom: 10%;
      }

      @media #{$medium-only} {
        bottom: 19%;
      }

      @media #{$small-only} {
        display: none;
      }
    }
  }
}

@keyframes falling {
  0% {
    transform: translate(0, 0);
    animation-timing-function: ease-in-out;
  }

  8% {
     transform: translate(+60%, +80%) rotate(-20deg);
     animation-timing-function: ease-in-out;
  }

  16% {
     transform: translate(-60%, +140%) rotate(+10deg);
     animation-timing-function: ease-in-out;
  }

  26% {
     transform: translate(+100%, +300%) rotate(-40deg);
     animation-timing-function: ease-in-out;
  }

  36% {
     transform: translate(-130%, +500%) rotate(+20deg);
     animation-timing-function: ease-in-out;
  }

  54% {
     transform: translate(+180%, +800%) rotate(-40deg);
     animation-timing-function: ease-in-out;
  }

  72% {
     transform: translate(-200%, +1300%) rotate(+20deg);
     animation-timing-function: ease-in-out;
  }

  85% {
     transform: translate(+230%, +1800%) rotate(-40deg);
     animation-timing-function: ease-in-out;
  }

  90% {
     opacity: 1;
  }

  100% {
     transform: translate(+230%, +1800%) rotate(-40deg);
     animation-timing-function: ease-in-out;
     opacity: 0;
  }
}

@keyframes float {
  0% {
    left: -15%;
    transform: translateY(+25%);
  }

  20% {
    transform: translateY(-25%);
  }

  40% {
    transform: translateY(+25%);
  }

  60% {
   transform: translateY(-25%);
  }

  80% {
    transform: translateY(+25%);
  }

  100% {
    left: 100%;
    transform: translateY(-25%);
  }
}

section#summary {
  position: relative;
  width: 100%;
  display: block;

  .banner {
    background: url(../img/banner.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: -6%;
    z-index: 1000;
    display: block;
    width: 50%;
    max-width: 600px;
    height: 250px;
    color: black;
    font-size: 1.1rem;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);

    @media #{$small-only} {
      width: 90%;
      top: 0;
    }

    @media #{$medium-only} {
      width: 65%;

    }

    h1 {
      font-size: 3.8rem;
      font-weight: 700;
      line-height: 1.5;
      color: #333;
      margin-top: 10%;
      letter-spacing: 2px;

      @media #{$medium-only} {
        line-height: 1.5;
        font-size: 3rem;
      }

      @media #{$small-only} {
        font-size: 2.5rem;
      }
    }
  }
}

section#material {
  height: 2500px;
  background: $tertiary;
  position: relative;
  display: block;

  @media #{$small-only} {
    height: 3300px;
  }

  .grass {
    width: 100%;
    height: auto;
    bottom: 0;
    margin: 0 auto;
    position: absolute;
    top: -70px;
    z-index: 12;
    left: 0;
    right: 0;

    @media #{$medium-up} {
      top: -120px;
    }
  }

  .tree1 {
    position: absolute;
    top: -110px;
    z-index: 11;
    width: 20%;

    @media #{$medium-up} {
      top: -220px;
    }
  }

  .tree2{
    position: absolute;
    top: -100px;
    z-index: 11;
    right: 2vw;
    width: 20%;

    @media #{$medium-up} {
      top: -220px;
    }
  }

  .tree3 {
    position: absolute;
    top: -90px;
    z-index: 11;
    left: 12%;
    width: 17%;

    @media #{$medium-up} {
      top: -120px;
    }
  }

  .paper-wrapper {
    position: relative;
    display: block;
    top: 6rem;
    margin: 0 auto;
    width: 90%;
    max-width: 800px;
    z-index: 13;

    @media #{$small-only} {
      top: 8rem;
    }
  }

  .paper {
    background: #e2f0fa;
    display: block;
    width: 100%;
    padding: 2rem;
    font-size: 1rem;
    display: block;

    h2 {
      font-size: 1.8rem;
      font-weight: 600;
    }

    h3 {
      font-size: 1.2rem;
      font-weight: 600;
    }

    .column {
      padding-left: 0;
    }

    a.download {
      width: 100%;
      background-color: $secondary;
      border-radius: 5px;
      color: #222;
      padding: 1rem 1.2rem;
      font-weight: 600;

    }

    a.fun {
      width: 100%;
      background-color: darken($secondary, 10);
      border-radius: 5px;
      color: #222;
      padding: 1rem 1.2rem;
      font-weight: 600;
    }

    a.facebook {
      width: 100%;
      background-color: #3a5a98;
      border-radius: 5px;
      color: white;
      padding: 1rem 1.2rem;
      font-weight: 600;
      position: relative;

      &:before {
        content: "";
        background-image: url(../img/facebook.svg);
        height: 30px;
        width: 30px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left;
        z-index: 15;
        position: absolute;
        left: 2%;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .paper-top {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: -1px;

    img {
      width: 100%;
    }
  }

  .paper-bottom {
    display: block;
    width: 110%;
    float: right;

    img {
      width: 100%;
    }
  }

  .owl {
    z-index: 12;
    left: 5%;
    position: absolute;
    bottom: 2%;
    width: 20%;
    max-height: 600px;

    @media #{$large-up} {
      bottom: 10%;
      width: 20%;
      left: 5%;
    }
  }

  .signs {
    z-index: 12;
    right: 2%;
    position: absolute;
    top: 13%;
    width: 20%;

    @media #{$small-only} {
      display: none
    }

    @media #{$medium-only} {
      top: 5%;
    }
  }

  .waves {
    width: 100%;
    position: absolute;
    top: 95%;
    left: 0;
    right: 0;
    z-index: 11;
  }

  .dog {
    position: absolute;
    bottom: 2%;
    z-index: 12;
    right: 10%;
    width: 40%;
    max-height: 26vh;

    @media #{$large-up} {
      bottom: 5%;
    }

    @media #{$medium-up} {
      bottom: 5%;
      width: 30%;
    }
  }

  .ant {
    position: absolute;
    z-index: 14;
    width: 4%;
    height: 4%;
    max-height: 4vh;

    &.ant1 {
      top: 20%;
      transform: rotate(45deg);
      left: 5%;
    }

    &.ant2 {
      top: 3%;
      transform: rotate(175deg);
      right: 10%;
    }

    &.ant3 {
      bottom: 5%;
      transform: rotate(240deg);
      left: 10%;
    }

    &.ant4 {
      bottom: 30%;
      transform: rotate(320deg);
      right: 30%;
    }
  }

  .flower {
    position: absolute;
    z-index: 11;
    bottom: 2%;
    left: 20%;
    width: 26%;
    max-height: 30vh;

    @media #{$small-only} {
      bottom: 5%;
    }
  }

  .blueberries {
    position: absolute;
    z-index: 11;
    bottom: 0;
    right: -3%;
    width: 10%;
    max-height: 30vh;

    @media #{$small-only} {
      bottom: 5%;
    }
  }

  .flowergirl {
    position: absolute;
    z-index: 12;
    top: 35%;
    right: 3%;
    width: 8%;

    @media #{$small-only} {
      display: none;
    }
  }

  .boyonbike {
    position: absolute;
    z-index: 12;
    top: 25%;
    right: 18%;
    width: 13%;
    max-height: 40vh;

    @media #{$small-only} {
      display: none;
    }

    @media #{$medium-only} {
      right: 82%;
    }
  }

  .jumpinggirl {
    position: absolute;
    z-index: 12;
    top: 13%;
    left: 15%;
    width: 13%;

    @media #{$small-only} {
      display: none;
    }

    @media #{$medium-only} {
      top: 5%;
      left: 10%;
    }
  }

  .pinkbird {
    position: absolute;
    z-index: 14;
    top: 2%;
    right: 26%;
    width: 8%;

    @media #{$small-only} {
      display: none;
    }
  }

  .logo {
    max-height: 70px;
    max-width: 160px;
    margin-bottom: 1rem;
  }
}
